<!--
 * @Author: goodLiang
 * @Date: 2023-02-21 13:47:43
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-13 16:16:40
 * @FilePath: /vue3-template/src/views/enter/components/businessForm.vue
-->
<template>
  <div class="panel-heading">
    <div class="panel-heading__title">企业主体信息</div>
  </div>
  <div class="panel-body">
    <el-form
      ref="formDataRef"
      :model="myFormData"
      :rules="formRules"
      label-width="110px"
      label-position="left"
      :disabled="isApply"
    >
      <div class="sub-heading">
        <div class="sub-heading__title">个体工商户信息</div>
        <div class="sub-heading__desc">（暂时只支持境内企业入驻）</div>
      </div>
      <el-form-item label="企业名称" prop="sign_name">
        <el-col :span="11">
          <el-input
            name="sign_name"
            v-model="myFormData.sign_name"
            placeholder="请输入企业名称"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="企业简称" prop="short_name">
        <el-col :span="11">
          <el-input
            name="short_name"
            v-model="myFormData.short_name"
            placeholder="请输入企业简称"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="营业执照" prop="licence_url">
        <el-col :span="11">
          <uploadPic
            :img-url="myFormData.oss_licence_url"
            up-name="上传营业执照"
            :fileTypeYb="3"
            @on-success="
              (val, data) => handlePicSuccess(val, data, 'licence_url')
            "
          >
            <!-- @on-change="(val) => handlePicChange(val, 'customer_cert_pid')"> -->
          </uploadPic>
        </el-col>
      </el-form-item>
      <el-form-item label="上传开户许可证照片" prop="open_account_licence_url">
        <el-col :span="11">
          <uploadPic
            :img-url="myFormData.oss_open_account_licence_url"
            up-name="开户许可证"
            :fileTypeYb="3"
            @on-success="
              (val, data) =>
                handlePicSuccess(val, data, 'open_account_licence_url')
            "
          >
            <!-- @on-change="(val) => handlePicChange(val, 'customer_cert_pid')"> -->
          </uploadPic>
        </el-col>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="licence_no">
        <el-col :span="11">
          <el-input
            name="licence_no"
            maxlength="18"
            v-model="myFormData.licence_no"
            placeholder="请输入统一社会信用代码"
          />
        </el-col>
      </el-form-item>
      <!-- <el-form-item label="营业执照注册地址" prop="customer_address">
                <el-col :span="11">
                    <el-input name="customer_address" v-model="myFormData.customer_address" placeholder="请输入营业执照注册地址" />
                </el-col>
            </el-form-item>
            <el-form-item label="营业执照起始日期" prop="customer_cert_expiry">
                <el-col :span="11">
                    <el-date-picker name="customer_cert_expiry" v-model="myFormData.customer_cert_expiry" type="date"
                        placeholder="请选择营业执照起始日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD" :editable="false"
                        class="i-block">
                    </el-date-picker>
                </el-col>
            </el-form-item>
            <el-form-item label="营业执照截止日期" prop="customer_cert_expiry_end">
                <el-col :span="11">
                    <el-date-picker name="customer_cert_expiry_end" v-model="myFormData.customer_cert_expiry_end" type="date"
                        placeholder="请选择营业执照截止日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD" :editable="false"
                        class="i-block" :disabled="myFormData.customer_cert_expiry_end == '长期'">
                    </el-date-picker>
                </el-col>
                <el-col :span="11">
                    <div class="m-l-10"><el-checkbox v-model="myFormData.customer_cert_expiry_end"
                            name="customer_cert_expiry_end" true-label="长期" false-label="">长期</el-checkbox>
                    </div>
                </el-col>
            </el-form-item> -->
      <el-form-item label="实际经营地址">
        <el-col :span="6">
          <el-select
            v-model="myFormData.province"
            @change="changeAddress($event, 1)"
            placeholder="请选择省"
          >
            <el-option
              v-for="item in address.p"
              :key="item.address_id"
              :label="item.address_name"
              :value="item.address_id"
            ></el-option>
          </el-select>
          <!-- <span v-else>{{ myFormData.province_name }}</span> -->
        </el-col>
        <el-col :span="6">
          <el-select
            v-model="myFormData.city"
            @change="changeAddress($event, 2)"
            placeholder="请选择市"
          >
            <el-option
              v-for="item in address.c"
              :key="item.address_id"
              :label="item.address_name"
              :value="item.address_id"
            ></el-option>
          </el-select>
          <!-- <span v-else>{{ myFormData.city_name }}</span> -->
        </el-col>
        <el-col :span="6">
          <el-select v-model="myFormData.district" placeholder="请选择区">
            <el-option
              v-for="item in address.d"
              :key="item.address_id"
              :label="item.address_name"
              :value="item.address_id"
            ></el-option>
          </el-select>
          <!-- <span v-else>{{ myFormData.district_name }}</span> -->
        </el-col>
        <el-col :span="11">
          <el-input
            style="margin-top: 20px"
            name="address"
            v-model="myFormData.address"
            placeholder="请输入营业执照注册地址"
          />
        </el-col>
      </el-form-item>
      <div class="i-space-line"></div>
      <div class="sub-heading">
        <div class="sub-heading__title">结算/发票信息</div>
      </div>
      <el-form-item label="纳税人分类" prop="taxpayer">
        <el-col :span="11">
          <el-select
            v-model="myFormData.taxpayer"
            placeholder="请选择纳税人分类"
            class="i-block"
          >
            <el-option
              v-for="item in options.taxpayerTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="发票类型" prop="invoice_type">
        <el-col :span="11">
          <el-select
            v-model="myFormData.invoice_type"
            placeholder="请选择发票类型"
            class="i-block"
          >
            <el-option
              v-for="item in options.invoiceTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="发票类型">
        <el-col :span="11"> 经销 </el-col>
      </el-form-item>
      <el-form-item label="结算方式" prop="settlement_type">
        <el-col :span="11">
          <el-select
            v-model="myFormData.settlement_type"
            placeholder="请选择结算方式"
            class="i-block"
          >
            <el-option
              v-for="item in options.settleAccountsTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  watch,
  defineProps,
  defineExpose,
  computed
} from 'vue'
import uploadPic from '@/components/uploadBankPic/index.vue'
import options from '@/const/index'
import { getProvince, getCity, getDistrict } from '@/api/user.js'

const props = defineProps({
  formData: {
    type: Object,
    default: () => {}
  },
  isApply: {
    type: Boolean,
    default: false
  },
  isResetSubmit: {
    type: Boolean,
    default: false
  }
})
const myFormData = computed(() => props.formData)
const formDataRef = ref()
const formRules = reactive({
  sign_name: {
    required: true,
    message: '请输入企业名称',
    trigger: 'blur'
  },
  short_name: {
    required: true,
    message: '请输入企业简称',
    trigger: 'blur'
  },
  licence_url: {
    required: true,
    message: '请上传营业执照',
    trigger: 'blur'
  },
  licence_no: {
    required: true,
    message: '请输入统一社会信用代码',
    trigger: 'blur'
  },
  // customer_address: {
  //   required: true,
  //   message: '请输入营业执照注册地址',
  //   trigger: 'blur',
  // },
  // customer_cert_expiry: {
  //   required: true,
  //   message: '请选择营业执照开始日期',
  //   trigger: 'change',
  // },
  // customer_cert_expiry_end: {
  //   required: true,
  //   message: '请选择营业执照截止日期',
  //   trigger: 'change',
  // },
  taxpayer: {
    required: true,
    message: '请选择纳税人分类',
    trigger: 'change'
  },
  invoice_type: {
    required: true,
    message: '请选择发票类型',
    trigger: 'change'
  },
  settlement_type: {
    required: true,
    message: '请选择结算方式',
    trigger: 'change'
  },

  province: {
    required: true,
    message: '请选择省',
    trigger: 'change'
  },
  city: {
    required: true,
    message: '请选择市',
    trigger: 'change'
  },
  district: {
    required: true,
    message: '请选择区',
    trigger: 'change'
  },
  address: {
    required: true,
    message: '请填写详细地址',
    trigger: 'blur'
  }
})

const address = reactive({
  p: [],
  c: [],
  d: []
})

onMounted(() => {
  if (props.isResetSubmit) {
    getCityList(props.formData.province)
    getDistrictList(props.formData.city)
  }
  getProvinceList()
})

watch([() => props.formData.province, () => props.formData.city], (newval) => {
  if (props.isApply) {
    getCityList(newval[0])
    getDistrictList(newval[1])
  }
})

const getProvinceList = () => {
  getProvince().then((res) => {
    address.p = res.list || []
  })
}

const getCityList = (address_id) => {
  getCity({ address_id }).then((res) => {
    address.c = res.list || []
  })
}

const getDistrictList = (address_id) => {
  getDistrict({ address_id }).then((res) => {
    address.d = res.list || []
  })
}

const changeAddress = (address_id, type) => {
  if (type === 1) {
    getCityList(address_id)
  }
  if (type === 2) {
    getDistrictList(address_id)
  }
}

const handlePicSuccess = (val, data, key) => {
  myFormData.value[key] = data?.body?.mer_qual_url
  myFormData.value[`oss_${key}`] = val?.pic_full_url
  formDataRef.value.validateField(key)
}

defineExpose({
  validate: () => {
    return formDataRef.value.validate()
  }
})
</script>

<style lang="scss" scoped></style>

/*
 * @Author: goodLiang
 * @Date: 2023-02-20 18:43:44
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-02-25 11:33:24
 * @FilePath: /zhaoshang/src/api/bank.js
 */

import request from '@/utils/request'

/**
 * 上传
 */
export const bankUpload = (data = {}) => {
  return request({
    url: '/pay/upload/upload',
    method: 'POST',
    data: { ...data, seller_status: true }
  })
}
/**
 * 对私编码查询
 */
export const getBankCode = (data = {}) => {
  return request({
    // url: '/pay/bank/getBankCode',
    url: '/pay/account/queryCardBinInfo',
    method: 'POST',
    data: { ...data, seller_status: true }
  })
}

/**
 * 验证码
 */
export const getBankSms = (data = {}) => {
  return request({
    url: '/pay/account/smsCode',
    method: 'POST',
    data: { ...data, seller_status: true }
  })
}

/**
 * 银行打歀
 */
export const payBank = (data = {}) => {
  return request({
    url: '/pay/account/enterprisePaymentVerify',
    method: 'POST',
    data: { ...data, seller_status: true }
  })
}

/**
 * 银行列表
 */
export const queryHeadBankList = (data = {}) => {
  return request({
    url: '/pay/account/queryHeadBankList',
    method: 'POST',
    data: { ...data, seller_status: true }
  })
}

/**
 * 开户行支行分页查询
 */
export const queryMiddleSubBankList = (data = {}) => {
  return request({
    url: '/admin/popSeller/queryMiddleSubBankList',
    method: 'POST',
    data: { ...data, seller_status: true }
  })
}

<!--
 * @Author: goodLiang
 * @Date: 2023-02-20 17:11:44
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-11 10:30:56
 * @FilePath: /vue3-template/src/components/uploadBankPic/index.vue
-->
<template>
  <!-- <el-upload class="avatar-uploader" :action="uploadFile" :show-file-list="false" :on-success="onSuccess" :on-change="onChange"> -->
  <el-upload
    class="avatar-uploader"
    :show-file-list="false"
    :http-request="customUpload"
  >
    <img v-if="imgUrl" :src="imgUrl" class="photo" />
    <div class="no-photo" v-else>
      <img src="../../assets/photo_icon.png" class="no-photo__icon" />
      <div class="no-photo__label">{{ upName }}</div>
    </div>
  </el-upload>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import axios from 'axios'

import { uploadFile, uploadFileYb } from '@/api/user'

const props = defineProps({
  imgUrl: {
    type: String,
    default: ''
  },
  upName: {
    type: String,
    default: '请选择图片'
  },
  fileTypeYb: {
    type: Number,
    default: 1
  }
})
const emits = defineEmits(['onSuccess', 'onChange'])
// let picUid = ''
// const onChange = async (file, fileList) => {
//   console.log('file', file)
//   if (file.uid === picUid) return
//   const fileType = file.raw.type.split('/')[1]
//   picUid = file.uid
//   getBase64(file.raw)
//     .then((base64) => {
//       bankUpload({
//         pic: base64,
//         fileType
//       })
//         .then((res) => {
//           ElMessage.success('上传成功')
//           emits('onChange', res.pic_id)
//         })
//         .catch((err) => {
//           ElMessage.error('上传失败，请重新上传')
//           emits('onSuccess', {})
//           console.log('err', err)
//         })
//     })
//     .catch((err) => {})
// }
// const onSuccess = ({ header, body }) => {
//   if (header.res_code != 0) {
//     ElMessage.error(header.message)
//   } else {
//     emits('onSuccess', body)
//   }
// }

const customUpload = (fileObj) => {
  const formData = new FormData()
  formData.append('file', fileObj.file)
  return axios
    .request({
      url: uploadFile,
      method: 'post',
      data: formData
    })
    .then((response) => {
      uploadYb(fileObj, response.data.body)
    })
    .catch((error) => {
      console.log('customUpload :>> ', error)
    })
}
const uploadYb = (fileObj, body) => {
  const formData = new FormData()
  formData.append('file', fileObj.file)
  formData.append('fileType', props.fileTypeYb)
  return axios
    .request({
      url: uploadFileYb,
      method: 'post',
      data: formData
    })
    .then((response) => {
      emits('onSuccess', body, response.data)
    })
    .catch((error) => {
      console.log('uploadYb :>> ', error)
    })
}
</script>

<style lang="scss" scoped>
.no-photo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon {
    width: 40px;
    height: 40px;
  }

  &__label {
    font-size: 12px;
    color: #484d52;
  }
}
</style>
<style>
.avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  background: #f7fafc;

  .photo {
    width: 100px;
    height: 100px;
    vertical-align: top;
  }
}
</style>

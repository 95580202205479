<!--
 * @Author: goodLiang
 * @Date: 2023-02-15 17:16:14
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-11 15:55:53
 * @FilePath: /vue3-template/src/views/enter/index.vue
-->
<template>
  <div class="home">
    <div class="i-page">
      <div class="panel panel-bg">
        <div class="panel-body">
          <el-steps
            :active="step - 1"
            align-center
            :key="stepKey"
            process-status="finish"
          >
            <el-step title="签署合同" />
            <el-step title="店铺经营信息" />
            <el-step title="企业主体信息" v-if="formData.account_type != 3" />
            <el-step title="银行账户信息" />
            <el-step title="入驻审核">
              <template v-slot:description>
                <!-- <div
                  :class="{ 'i-text-warning': userInfo.apply_state == 1 || userInfo.apply_state == 2, 'i-text-success': userInfo.apply_state == 3, 'i-text-danger': userInfo.apply_state == 4 }">
                  {{
                    applyStateDesc[userInfo.apply_state] }}</div>
                <div v-if="userInfo.apply_state == 2" class="i-text-danger i-pointer i-bold  i-font-14"
                  @click="payModal = true">去打款</div> -->

                <!-- REVIEWING REVIEW_BACK AGREEMENT_SIGNING BUSINESS_OPENING COMPLETED -->
                <div
                  class="i-text-warning"
                  v-if="
                    userInfo.apply_state &&
                    formData.application_status === 'REVIEWING'
                  "
                >
                  运营待审核
                </div>
                <div
                  class="i-text-danger"
                  v-if="
                    userInfo.apply_state &&
                    formData.application_status === 'REVIEW_BACK'
                  "
                >
                  运营审核失败
                </div>
                <div
                  class="i-text-warning"
                  v-if="
                    userInfo.apply_state &&
                    formData.application_status === 'AGREEMENT_SIGNING'
                  "
                >
                  审核通过，待签署协议
                </div>
                <div
                  class="i-text-warning"
                  v-if="
                    userInfo.apply_state &&
                    formData.application_status === 'BUSINESS_OPENING'
                  "
                >
                  <span v-if="formData.account_type != 3"
                    >协议已签署，正在入驻</span
                  >
                  <span v-if="formData.account_type == 3">正在入驻</span>
                </div>
                <div
                  class="i-text-success"
                  v-if="
                    userInfo.apply_state &&
                    formData.application_status === 'COMPLETED'
                  "
                >
                  运营审核成功
                </div>
                <!-- <div class="i-text-warning" v-if="userInfo.apply_state && formData.application_status === ''"></div> -->
              </template>
            </el-step>
            <el-step
              title="入驻成功"
              :status="userInfo.apply_state == 3 ? 'finish' : ''"
            />
          </el-steps>
        </div>
      </div>
      <div class="panel">
        <selectType
          ref="selectTypeRef"
          v-model:form-data="formData"
          v-if="step === 0 && !isApply"
        ></selectType>
        <agreementText
          ref="agreementTextRef"
          v-model:form-data="formData"
          v-if="step === 1 && !isApply"
        >
        </agreementText>
        <shopForm
          ref="shopFormRef"
          v-model:form-data="formData"
          :isApply="isApply"
          v-if="step === 2 || isApply"
        ></shopForm>
        <businessForm
          ref="businessFormRef"
          v-model:form-data="formData"
          :isApply="isApply"
          :isResetSubmit="isResetSubmit"
          v-if="
            (formData.account_type != 3 && step === 3) ||
            (formData.account_type != 3 && isApply)
          "
        >
        </businessForm>
        <bankForm
          ref="bankFormRef"
          v-model:form-data="formData"
          :isApply="isApply"
          v-if="
            (formData.account_type != 3 ? step === 4 : step === 3) || isApply
          "
        >
        </bankForm>
        <div class="panel-footer">
          <div class="submit-btn" v-if="!isApply">
            <div class="submit-btn__item" v-if="step > 0">
              <el-button
                type="primary"
                size="large"
                plain=""
                @click="handlePreStep"
                class="i-block"
                >上一步</el-button
              >
            </div>
            <div class="submit-btn__item">
              <el-button
                type="primary"
                size="large"
                @click="handleNextStep"
                class="i-block"
                >下一步</el-button
              >
            </div>
          </div>
          <div class="submit-btn" v-if="isApply && userInfo.apply_state == 4">
            <div class="submit-btn__item">
              <el-button
                type="primary"
                size="large"
                plain
                @click="handleResetSubmit"
                class="i-block"
                >重新提交</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="payModal" title="待企业打款验证" width="400px">
      <!-- <el-input v-model.number="payMoney" placeholder="请输入打款金额" /> -->
      <div class="el-input">
        <div class="el-input__wrapper">
          <input
            class="el-input__inner"
            v-model.number="payMoney"
            placeholder="请输入打款金额(元)"
          />
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handlePayBank">
            确定打款
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- <el-dialog
      v-model="applySuccessModal"
      title="恭喜你"
      width="400px"
      :close-on-click-modal="false"
    >
      <div class="success-tips">
        <img src="../../assets/success_small_icon.png" />
        恭喜您，入驻成功。请登录商家后台并收藏网址…
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleToAdmin">
            马上进入
          </el-button>
        </span>
      </template>
    </el-dialog> -->
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import selectType from './components/selectType.vue'
import agreementText from './components/agreementText.vue'
import shopForm from './components/shopForm.vue'
import businessForm from './components/businessForm.vue'
import bankForm from './components/bankForm.vue'
import { submitBusinessInfo, getBusinessInfo } from '../../api/business'
import { payBank } from '../../api/bank'

const store = useStore()
const userInfo = computed(() => store.getters.userInfo) // userInfo.apply_state   申请状态    1|待银行审核 2|待银行打款 3|入驻成功 4|入驻失败
// const applyStateDesc = ['', '待银行审核', '待银行打款', '审核成功', '审核失败']

const step = ref(0)
const formData = reactive({
  isAgreement: false, // 是否同意协议
  shop_name: '', // 店铺名称
  account_type: '', // 1 企业 2 个体工商户 3 个人
  agreement: false,
  customer_name: '', // 企业名称
  customer_short_name: '', // 企业简称
  customer_cert_no: '', // 营业执照注册号
  customer_cert_pid: '', // 营业执照扫描件图片ID
  customer_cert_image: '', // 营业执照扫描件图片
  customer_address: '', // 营业执照注册地址
  customer_cert_expiry: '', // 营业执照起始日期
  customer_cert_expiry_end: '', // 营业执照截止日期

  short_name: '', // 法人姓名(个人)
  sign_name: '', // 法人姓名(个人)
  legal_licence_no: '', // 法人身份证号码(个人)
  oss_legal_licence_back_url: '', // 法人身份证国徽面(个人)照片 ID
  oss_legal_licence_front_url: '', // 法人身份证人像面照ID(个人)
  legal_licence_back_url: '', // 法人身份证国徽面照片(个人)
  legal_licence_front_url: '', // 法人身份证人像面照(个人)
  // corprate_cert_expiry: '',//法人身份证起始日期（个人）
  // corprate_cert_expiry_end: '', //法人身份证截止日期(个人)
  contact_name: '',
  contact_mobile: '', // 联系人手机号码
  contact_email: '', // 联系人邮箱
  mobile: '', // 法人手机号(用于发送验证码)(个人)
  //   bank_card_type: '',//银行账户类型 01 对私账户 02 对公账户(个人)
  bank_account_type: '', // 银行账户类型
  bank_card_no: '', // 银行账号(个人)
  bank_name: '', // 银行名称(个人)
  bank_code: '', // 银行编码(个人)
  bank_no: '', // 银行联行号(个人)
  sms_code: '', // 验证码(个人)

  business_categorys: [1], // 经营类目 1酒类
  owner_type: '', // 超级货主类型 1 寄卖 2 个人 3商城贸易
  shop_vendor_type: '', // 商家类型 1 酒厂, 2 经销商,3 个人,4 歌德酒市,5 虚拟关联销售,6 其他
  invoice_type: '', // 发票分类 1增值税专票 2增值税普票
  taxpayer: '', // 纳税人分类 1小规模纳税人 2一般纳税人
  business_type: 1, // 经营方式 1经销 2代销
  settlement_type: 1, // 结算方式 1先货后款 2先款后货

  account_name: '', // 账户持有人姓名
  sub_bank_name: '', // 开户行支行
  bank_number: '', // 开户行联行号,开户行支行号码

  province: '',
  city: '',
  district: '',
  address: '',
  licence_url: ''
})

const isResetSubmit = ref(false) // 是否重置提交
const isApply = ref(false) // 是否审核
const stepKey = ref(new Date())
const payModal = ref(false) // 打歀弹窗
const payMoney = ref('')
const applySuccessModal = ref(false) // 审核成功

const selectTypeRef = ref(null)
const agreementTextRef = ref(null)
const shopFormRef = ref(null)
const businessFormRef = ref(null)
const bankFormRef = ref(null)

const comNames = reactive([
  selectTypeRef,
  agreementTextRef,
  shopFormRef,
  businessFormRef,
  bankFormRef
])

const handlePreStep = () => {
  step.value -= 1
}
const handleNextStep = () => {
  // step.value += 1
  comNames[step.value] &&
    comNames[step.value].value.validate().then(() => {
      if (comNames.length - 1 === step.value) {
        if (formData.account_type === 3) {
          formData.sign_name = formData.short_name = formData.contact_name
        }
        if (formData.account_type === 1) {
          formData.sign_type = 'ENTERPRISE'
        }
        if (formData.account_type === 2) {
          formData.sign_type = 'INDIVIDUAL'
        }
        submitBusinessInfo(formData)
          .then(async (res) => {
            await store.dispatch('user/getUserInfo')
            step.value += 1
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        step.value += 1
      }
    })
}
const _getBusinessInfo = () => {
  getBusinessInfo({ seller_id: userInfo.value.id })
    .then((res) => {
      if (res.account_type === 3) {
        res.contact_name = res.sign_name
      }
      if (res.account_type !== 3) {
        res.province = Number(res.province)
        res.city = Number(res.city)
        res.district = Number(res.district)
        res.mobile = res.contact_mobile
      }
      console.log('_getBusinessInfo :>> ', res)
      Object.assign(formData, res)
    })
    .catch((err) => {
      console.log(err)
    })
}

onBeforeMount(async () => {
  if (userInfo.value.apply_state != null) {
    _getBusinessInfo()
  }
})

// 银行打歀
const handlePayBank = () => {
  if (payMoney.value <= 0) {
    ElMessage.error('请输入打款金额')
    return
  }
  payBank({ receive_amt: payMoney.value * 100 })
    .then((res) => {
      store.dispatch('user/getUserInfo')
      payModal.value = false
    })
    .catch((err) => {
      console.log(err)
    })
}

// const handleToAdmin = () => {
//   applySuccessModal.value = false

// }

const handleResetSubmit = () => {
  isApply.value = false
  step.value = 2
  isResetSubmit.value = true
}

// 监听审核状态
watch(
  () => userInfo.value.apply_state,
  (val) => {
    stepKey.value = new Date()
    if (val != null) {
      isApply.value = true
    }
    if (val === 3) {
      applySuccessModal.value = true
    }
  },
  {
    immediate: true
  }
)

// 监听店铺类型
watch(
  () => formData.account_type,
  (val) => {
    stepKey.value = new Date() // 重绘step
    if (!isApply.value) {
      formData.owner_type = '' // 重置入驻类型
      formData.bank_card_type = '' // 重置银行账户类型
    }
    if (val === 3) {
      comNames.indexOf(businessFormRef) !== -1 && comNames.splice(3, 1)
      // formData.settlement_type = 1  //个人先货后歀
    } else {
      comNames.indexOf(businessFormRef) === -1 &&
        comNames.splice(3, 0, businessFormRef)
    }
    // 提交之后step变更
    if (userInfo.value.apply_state != null && !isResetSubmit.value) {
      step.value = comNames.length
    }
  }
)

watch(
  () => formData,
  () => {
    console.log('onFormData :>> ', formData)
  },
  { deep: true }
)
</script>

<style lang="scss" scoped>
.home {
  padding-top: 94px;
  background: #ebedf0;
  min-height: 100vh;
  padding-bottom: 50px;
}

.panel-bg {
  background: linear-gradient(180deg, #ffffff 0%, #fafdff 100%);
}

.success-tips {
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
}
</style>

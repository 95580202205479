/*
 * @Author: goodLiang
 * @Date: 2023-02-24 13:23:21
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-02-25 10:58:10
 * @FilePath: /zhaoshang/src/const/index.js
 */
const options = {
  entryTypes: [
    {
      value: 1,
      label: '寄卖'
    },
    {
      value: 2,
      label: '个人'
    },
    {
      value: 3,
      label: '商城贸易'
    }
  ],
  businessTypes: [
    {
      value: 1,
      label: '酒厂'
    },
    {
      value: 2,
      label: '经销商'
    },
    {
      value: 3,
      label: '个人'
    },
    {
      value: 4,
      label: '歌德酒市'
    },
    {
      value: 5,
      label: '虚拟关联销售'
    },
    {
      value: 6,
      label: '其它'
    }
  ],
  settleAccountsTypes: [
    {
      value: 1,
      label: '先货后款'
    },
    {
      value: 2,
      label: '先款后货'
    }
  ],
  taxpayerTypes: [
    {
      value: 1,
      label: '小规模纳税人'
    },
    {
      value: 2,
      label: '一般纳税人'
    }
  ],
  invoiceTypes: [
    {
      value: 1,
      label: '增值税专票'
    },
    {
      value: 2,
      label: '增值税普票'
    }
  ]
}
export default options

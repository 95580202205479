<!--
 * @Author: goodLiang
 * @Date: 2023-02-19 18:47:46
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-13 16:16:44
 * @FilePath: /vue3-template/src/views/enter/components/shopForm.vue
-->
<template>
  <div class="panel-heading">
    <div class="panel-heading__title">开店信息</div>
  </div>
  <div class="panel-body">
    <!-- REVIEWING REVIEW_BACK AGREEMENT_SIGNING BUSINESS_OPENING COMPLETED -->
    <div class="i-tips" v-if="!(userInfo.apply_state != null)">
      <div class="i-tips__hd">
        <img src="../../../assets/tips_small_icon.png" alt="" srcset="" />
      </div>
      <div class="i-tips__bd">
        请注意规范正确填写。<span class="i-bold"
          >如因选择错误出现相关问题，由商家承担相关责任。</span
        >
      </div>
    </div>

    <div
      class="i-tips yellow"
      v-if="
        userInfo.apply_state && myFormData.application_status === 'REVIEWING'
      "
    >
      <div class="i-tips__hd">
        <img src="../../../assets/tips_yellow_icon.png" alt="" srcset="" />
      </div>
      <div class="i-tips__bd">
        运营审核中。<span class="i-bold">内容不可修改</span>
      </div>
    </div>

    <div
      class="i-tips red"
      v-if="
        userInfo.apply_state && myFormData.application_status === 'REVIEW_BACK'
      "
    >
      <div class="i-tips__hd">
        <img src="../../../assets/tips_red_icon.png" alt="" srcset="" />
      </div>
      <div class="i-tips__bd">
        <div>
          运营审核失败。<span class="i-bold">请重新申请。</span
          ><span class="i-bold" v-if="!!userInfo.error_reason">失败原因：</span
          >{{ userInfo.error_reason }}
        </div>
      </div>
    </div>

    <!-- || myFormData.application_status === 'BUSINESS_OPENING' -->
    <div
      class="i-tips yellow"
      v-if="
        userInfo.apply_state &&
        myFormData.application_status === 'AGREEMENT_SIGNING'
      "
    >
      <div class="i-tips__hd">
        <img src="../../../assets/tips_yellow_icon.png" alt="" srcset="" />
      </div>
      <div class="i-tips__bd">
        审核通过
        <div v-if="myFormData.account_type !== 3">
          ，<span class="i-bold" style="cursor: pointer" @click="goSign"
            >点击此处</span
          >签署协议即可入驻成功
        </div>
      </div>
    </div>

    <div
      class="i-tips green"
      v-if="
        userInfo.apply_state && myFormData.application_status === 'COMPLETED'
      "
    >
      <div class="i-tips__hd">
        <img src="../../../assets/tips_green_icon.png" alt="" srcset="" />
      </div>
      <div class="i-tips__bd">入驻成功</div>
    </div>

    <el-form
      ref="formDataRef"
      :model="myFormData"
      :rules="formRules"
      label-width="110px"
      label-position="left"
      :disabled="isApply"
    >
      <div class="sub-heading">
        <div class="sub-heading__title">店铺基本信息</div>
        <div class="sub-heading__desc">（暂时只支持境内企业入驻）</div>
      </div>
      <el-form-item label="店铺名称" prop="shop_name">
        <el-col :span="11">
          <el-input
            name="shop_name"
            v-model="myFormData.shop_name"
            placeholder="请输入店铺名称"
          />
        </el-col>
        <el-col :span="11">
          <div class="shop-tips" @click="handelShopStandard">
            <img
              src="../../../assets/tips_small_icon.png"
              alt=""
              srcset=""
            />查看规范
          </div>
        </el-col>
      </el-form-item>
      <el-form-item label="主营类目">
        <el-col :span="11">
          <div>酒类</div>
        </el-col>
      </el-form-item>
      <el-form-item label="入驻类型" prop="owner_type">
        <el-col :span="11">
          <el-select
            v-model="myFormData.owner_type"
            placeholder="请选择入驻类型"
            class="i-block"
          >
            <el-option
              v-for="(item, index) in options.entryTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="
                (myFormData.account_type == 3 && index != 1) ||
                (myFormData.account_type == 2 && index != 2) ||
                (myFormData.account_type == 1 && index == 1)
              "
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="商家类型" prop="shop_vendor_type">
        <el-col :span="11">
          <el-select
            v-model="myFormData.shop_vendor_type"
            placeholder="请选择商家类型"
            class="i-block"
          >
            <el-option
              v-for="item in options.businessTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
      </el-form-item>
      <div class="i-space-line"></div>
      <div class="sub-heading">
        <div class="sub-heading__title">开店人基本信息</div>
      </div>
      <el-form-item label="姓名" prop="contact_name">
        <el-col :span="11">
          <el-input
            name="contact_name"
            v-model="myFormData.contact_name"
            placeholder="请输入真实姓名"
          />
        </el-col>
      </el-form-item>
      <div v-if="myFormData.account_type === 3">
        <el-form-item label="身份证号" prop="legal_licence_no">
          <el-col :span="11">
            <el-input
              name="legal_licence_no"
              maxlength="18"
              v-model="myFormData.legal_licence_no"
              placeholder="请输入身份证号"
            />
          </el-col>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="7" :offset="0">
            <el-form-item label="上传身份证像" prop="legal_licence_front_url">
              <uploadPic
                :img-url="myFormData.oss_legal_licence_front_url"
                up-name="上传头像面"
                @on-success="
                  (val, data) =>
                    handlePicSuccess(val, data, 'legal_licence_front_url')
                "
              >
                <!-- @on-change="(val) => handlePicChange(val, 'corprate_pic2')"> -->
              </uploadPic>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="0">
            <el-form-item prop="legal_licence_back_url" label-width="0">
              <uploadPic
                :img-url="myFormData.oss_legal_licence_back_url"
                up-name="上传国徽面"
                @on-success="
                  (val, data) =>
                    handlePicSuccess(val, data, 'legal_licence_back_url')
                "
              >
                <!-- @on-change="(val) => handlePicChange(val, 'corprate_pic1')">> -->
              </uploadPic>
            </el-form-item></el-col
          >
        </el-row>
      </div>
      <!-- <el-form-item label="身份证开始日期" prop="corprate_cert_expiry">
                <el-col :span="11">
                    <el-date-picker name="corprate_cert_expiry" v-model="myFormData.corprate_cert_expiry" type="date"
                        placeholder="请选择身份证开始日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD" :editable="false"
                        class="i-block">
                    </el-date-picker>
                </el-col>
            </el-form-item>
            <el-form-item label="身份证截止日期" prop="corprate_cert_expiry_end">
                <el-col :span="11">
                    <el-date-picker name="corprate_cert_expiry_end" v-model="myFormData.corprate_cert_expiry_end" type="date"
                        placeholder="请选择身份证截止日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD" :editable="false"
                        class="i-block" :disabled="myFormData.corprate_cert_expiry_end == '长期'">
                    </el-date-picker>
                </el-col>
                <el-col :span="11">
                    <div class="m-l-10"><el-checkbox v-model="myFormData.corprate_cert_expiry_end"
                            name="corprate_cert_expiry_end" true-label="长期" false-label="">长期</el-checkbox>
                    </div>
                </el-col>
            </el-form-item> -->
      <el-form-item
        label="联系人手机号码"
        prop="contact_mobile"
        v-if="myFormData.account_type != 3"
      >
        <el-col :span="11">
          <el-input
            name="contact_mobile"
            maxlength="11"
            v-model="myFormData.contact_mobile"
            placeholder="请输入联系人手机号码"
          />
        </el-col>
      </el-form-item>
      <el-form-item
        label="联系人邮箱"
        prop="contact_email"
        v-if="myFormData.account_type != 3"
      >
        <el-col :span="11">
          <el-input
            name="contact_email"
            v-model="myFormData.contact_email"
            placeholder="请输入联系人邮箱"
          />
        </el-col>
      </el-form-item>

      <div v-if="myFormData.account_type !== 3">
        <div class="i-space-line"></div>
        <div class="sub-heading">
          <div class="sub-heading__title">法人基本信息</div>
        </div>
        <el-form-item label="姓名" prop="legal_name">
          <el-col :span="11">
            <el-input
              name="legal_name"
              v-model="myFormData.legal_name"
              placeholder="请输入真实姓名"
            />
          </el-col>
        </el-form-item>
        <el-form-item label="身份证号" prop="legal_licence_no">
          <el-col :span="11">
            <el-input
              name="legal_licence_no"
              maxlength="18"
              v-model="myFormData.legal_licence_no"
              placeholder="请输入身份证号"
            />
          </el-col>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="7" :offset="0">
            <el-form-item label="上传身份证像" prop="legal_licence_front_url">
              <uploadPic
                :img-url="myFormData.oss_legal_licence_front_url"
                up-name="上传头像面"
                @on-success="
                  (val, data) =>
                    handlePicSuccess(val, data, 'legal_licence_front_url')
                "
              >
                <!-- @on-change="(val) => handlePicChange(val, 'corprate_pic2')"> -->
              </uploadPic>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="0">
            <el-form-item prop="legal_licence_back_url" label-width="0">
              <uploadPic
                :img-url="myFormData.oss_legal_licence_back_url"
                up-name="上传国徽面"
                @on-success="
                  (val, data) =>
                    handlePicSuccess(val, data, 'legal_licence_back_url')
                "
              >
                <!-- @on-change="(val) => handlePicChange(val, 'corprate_pic1')">> -->
              </uploadPic>
            </el-form-item></el-col
          >
        </el-row>
      </div>
    </el-form>
    <el-dialog
      v-model="shopStandard"
      title="歌德酒市入驻店铺名称规范"
      width="700px"
    >
      <div>
        1.店铺名称不得与已经通过审核的店铺名称重复。若两个或以上店铺申请相同的符合规定的店铺名称，歌德老酒行依照申请在先原则核定，未通过审核的店铺需要更换店铺名称后重新提交申请。<br />
        2.未经歌德老酒行许可，店铺名称、店铺标识、店铺简介等均不得使用“歌德特许”、“歌德授权”或其他带有类似含义的内容。<br />
        3.未经相关权利人授权并提交有效证明文件，店铺名称、店铺标识、店铺简介等均不得使用“特约经营”、“特约经销”、“总经销”、“总代理”、“厂家认证”、“官方店铺”或其他可能是买家误认为该店铺已经取得相应品牌授权的内容。<br />
        4.个人店铺的店铺名称不得使用“旗舰”、“专卖”、“专营”、“官方”、“直营”、“官字”、“官方认证”、“官方授权”、“特许经营”、“特约经销”或其他带有类似含义的内容。<br />
        5.店铺名称不得带有电话号码、电子邮箱、网址、二维码、即时通讯工具或其他联系信息（如QQ号码、微信号等）。<br />
        6.店铺名称不得包含具有下列情形的内容：<br />
        （1）有损国家、社会公共利益，或有损民族尊严的；<br />
        （2）侵犯他人合法权利的；<br />
        （3）夸大宣传、可能误导公众的；<br />
        （4）外国国家或地区的名称；<br />
        （5）国际组织的名称；<br />
        （6）政治敏感信息，包括但不限于国家领导人姓名、政党名称、党政军机关名称；<br />
        （7）含有封建文化糟粕、有消极政治影响，或违背少数民族习俗、带有歧视性的；<br />
        （8）与经营主体无关，含有除歌德老酒行外的其他电子商务平台信息；<br />
        （9）其他违反法律法规或社会善良风俗的。
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="shopStandard = false">
            我知道了
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, computed, defineProps, defineExpose } from 'vue'
import { useStore } from 'vuex'
import { getShopValidate } from '../../../api/business.js'
import uploadPic from '@/components/uploadBankPic/index.vue'
import { validatorIdCard, validatorMobile, validatorEmail } from '../rules.js'
import options from '@/const/index'

const props = defineProps({
  formData: {
    type: Object,
    default: () => {}
  },
  isApply: {
    type: Boolean,
    default: false
  }
})

const myFormData = computed(() => props.formData)

// 校验店铺名称
const shopNameValidate = () => {
  return (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入店铺名称'))
      return
    }
    getShopValidate({
      shop_name: value,
      shop_type: myFormData.value.account_type
    })
      .then((res) => {
        callback()
      })
      .catch((err) => {
        callback(new Error(err.message || '名称不符合规范'))
      })
  }
}

const store = useStore()
const userInfo = computed(() => store.getters.userInfo)

const formDataRef = ref()
const formRules = reactive({
  shop_name: {
    required: true,
    trigger: 'blur',
    validator: shopNameValidate()
  },
  legal_licence_back_url: {
    required: true,
    message: '请上传身份证反面照',
    trigger: 'blur'
  },
  oss_legal_licence_back_url: {
    required: true,
    message: '请上传身份证反面照',
    trigger: 'blur'
  },
  legal_licence_front_url: {
    required: true,
    message: '请上传身份证正面照',
    trigger: 'blur'
  },
  oss_legal_licence_front_url: {
    required: true,
    message: '请上传身份证正面照',
    trigger: 'blur'
  },
  contact_name: {
    required: true,
    message: '请输入身份证姓名',
    trigger: 'blur'
  },
  legal_licence_no: {
    required: true,
    trigger: 'blur',
    validator: validatorIdCard()
  },
  legal_name: {
    required: true,
    trigger: 'blur',
    message: '请输入法人姓名'
  },
  // corprate_cert_expiry: {
  //   required: true,
  //   message: '请输入身份证开始日期',
  //   trigger: 'change',
  // },
  // corprate_cert_expiry_end: {
  //   required: true,
  //   message: '请输入身份截止日期',
  //   trigger: 'change',
  // },
  owner_type: {
    required: true,
    message: '请选择入驻类型',
    trigger: 'change'
  },
  shop_vendor_type: {
    required: true,
    message: '请选择商家类型',
    trigger: 'change'
  },
  contact_mobile: {
    required: true,
    trigger: 'blur',
    validator: validatorMobile()
  },
  contact_email: {
    required: true,
    trigger: 'blur',
    validator: validatorEmail()
  }
})

// 店铺规范
const shopStandard = ref(false)
const handelShopStandard = () => {
  shopStandard.value = !shopStandard.value
}

// 上传图片处理
const handlePicSuccess = (val, data, key) => {
  myFormData.value[key] = data?.body?.mer_qual_url
  myFormData.value[`oss_${key}`] = val?.pic_full_url
  formDataRef.value.validateField(key)
}

defineExpose({
  validate: () => {
    return formDataRef.value.validate()
  }
})

const goSign = () => {
  window.open(props.formData.agreement_sign_url)
}
</script>

<style lang="scss" scoped>
.shop-tips {
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: rgba(0, 113, 219, 1);
  cursor: pointer;

  img {
    width: 14px;
    height: 14px;
  }
}
</style>

<!--
 * @Author: goodLiang
 * @Date: 2023-02-19 18:47:46
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-11 16:04:15
 * @FilePath: /vue3-template/src/views/enter/components/bankForm.vue
-->
<template>
  <div class="panel-heading">
    <div class="panel-heading__title">结算信息</div>
  </div>
  <div class="panel-body">
    <el-form
      ref="formDataRef"
      :model="myFormData"
      :rules="formRules"
      :label-width="myFormData.account_type !== 3?'152px':'120px'"
      label-position="left"
      :disabled="isApply"
    >
      <el-form-item label="银行账户类型" prop="bank_account_type">
        <el-col :span="11">
          <el-select
            v-model="myFormData.bank_account_type"
            placeholder="请选择银行账户类型"
            class="i-block"
          >
            <el-option
              v-for="(item, index) in bankCardType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="
                (myFormData.account_type == 1 && index == 0) ||
                (myFormData.account_type == 3 && index == 1)
              "
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item
        label="银行名称"
        prop="bank_code"
        v-if="myFormData.account_type !== 3"
      >
        <el-col :span="11">
          <el-select
            v-model="myFormData.bank_code"
            placeholder="请选择银行账户类型"
            class="i-block"
            filterable
          >
            <el-option
              v-for="item in bankList"
              :key="item.bank_code"
              :label="item.bank_name"
              :value="item.bank_code"
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="结算银行账户" prop="bank_card_no">
        <el-col :span="11">
          <el-input
            name="bank_card_no"
            maxlength="20"
            v-model="myFormData.bank_card_no"
            placeholder="请输入结算银行账户"
            @blur="handleBankCode"
          />
        </el-col>
      </el-form-item>
      <el-form-item :label="`账户持有人姓名${myFormData.account_type!==3?'/名称':''}`" prop="account_name">
        <el-col :span="11">
          <el-input
            name="bank_card_no"
            maxlength="20"
            v-model="myFormData.account_name"
            placeholder="请输入账户持有人姓名"
            @blur="handleBankCode"
          />
        </el-col>
      </el-form-item>
      <el-form-item
        label="结算开户行"
        prop="bank_name"
        v-if="myFormData.account_type === 3"
      >
        <el-col :span="11">
          <el-input
            name="bank_name"
            v-model="myFormData.bank_name"
            placeholder="请输入结算银行账户"
            readonly
            disabled
          />
        </el-col>
      </el-form-item>
      <el-form-item label="开户行支行" prop="sub_bank_name">
        <el-col :span="11">
          <el-select
            v-model="myFormData.sub_bank_name"
            placeholder="请选择银行账户类型"
            class="i-block"
            filterable
            value-key="id"
            :filter-method="subBankNameFilter"
            v-select-loadmore="loadmoreSubBankName"
            popper-class="sub-bank-name-dropdown"
            @change="subBankNameChange"
          >
            <el-option
              v-for="item in subBankList"
              :key="item.id"
              :label="item.bank_of_deposit"
              :value="item"
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-col :span="11">
          <el-input
            name="mobile"
            maxlength="11"
            v-model="myFormData.mobile"
            placeholder="请输入手机号"
          />
        </el-col>
        <!-- <el-col :span="11" v-if="!isApply">
          <div class="m-l-10 i-text-gray" v-if="isCountDown">
            {{ countDown }}
          </div>
          <div @click="getAuthCode" class="m-l-10 i-text-primary i-pointer" v-else>
            发送验证码
          </div>
        </el-col> -->
      </el-form-item>
      <!-- <el-form-item label="验证码输入" prop="sms_code">
        <el-col :span="11">
          <el-input name="sms_code" v-model="myFormData.sms_code" maxlength="6" placeholder="请输入验证码输入" />
        </el-col>
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  computed,
  defineProps,
  defineExpose,
  watch,
  onMounted
} from 'vue'
import { getBankCode, queryHeadBankList, queryMiddleSubBankList } from '../../../api/bank'
import { validatorMobile } from '../rules.js'

const vSelectLoadmore = {
  beforeMount(el, binding) {
    const body = document.querySelector('body')
    const element = body.querySelector('.sub-bank-name-dropdown .el-select-dropdown__wrap')
    element.addEventListener('scroll', () => {
      const { scrollTop, scrollHeight, clientHeight } = element
      const scrollDistance = scrollHeight - scrollTop - clientHeight
      if (scrollDistance <= 0) {
        binding.value()
      }
    })
  }
}

const props = defineProps({
  formData: {
    type: Object,
    default: () => {}
  },
  isApply: {
    type: Boolean,
    default: false
  }
})

const myFormData = computed(() => props.formData)

const bankList = ref([])
const subBankList = ref([
  // { id: 1, name: '支行1' },
  // { id: 2, name: '支行2' },
  // { id: 3, name: '支行3' },
  // { id: 4, name: '支行4' },
  // { id: 5, name: '支行5' },
  // { id: 6, name: '支行6' },
  // { id: 7, name: '支行7' },
  // { id: 8, name: '支行8' },
  // { id: 9, name: '支行9' },
  // { id: 10, ame: '支行10' },
  // { id: 11, name: '支行11' },
  // { id: 12, name: '支行12' },
  // { id: 13, name: '支行13' },
  // { id: 14, name: '支行14' },
  // { id: 15, name: '支行15' },
  // { id: 16, name: '支行16' },
  // { id: 17, name: '支行17' },
  // { id: 18, name: '支行18' }
])

onMounted(() => {
  queryHeadBankList({
    page_index: 1,
    page_size: 5000
  }).then((res) => {
    bankList.value = res.data
  })
})

watch(
  () => props.formData.bank_code,
  (newVal, oldVal) => {
    if (props.isApply) return
    myFormData.value.bank_name = bankList.value.find(
      (bank) => bank.bank_code === newVal
    )?.bank_name
  }
)

const bankCardType = reactive([
  {
    value: 'DEBIT_CARD',
    label: '对私账户'
  },
  {
    value: 'ENTERPRISE_ACCOUNT',
    label: '对公账户'
  }
])

const formDataRef = ref()
const formRules = reactive({
  bank_account_type: {
    required: true,
    message: '请选择银行账户类型',
    trigger: 'change'
  },
  bank_code: {
    required: props.formData.account_type !== 3,
    trigger: 'change',
    message: '请选择银行'
    // validator: validatorBankNo()
  },
  bank_card_no: {
    required: true,
    trigger: 'blur',
    message: '请输入银行账户卡号'
    // validator: validatorBankNo()
  },
  account_name: {
    required: true,
    trigger: 'blur',
    message: '请输入账户持有人姓名'
    // validator: validatorBankNo()
  },
  sub_bank_name: {
    required: true,
    trigger: 'blur',
    message: '请选择开户行支行'
    // validator: validatorBankNo()
  },
  mobile: {
    required: true,
    trigger: 'blur',
    validator: validatorMobile()
  }
  // sms_code: {
  //   required: true,
  //   message: '请输入验证码',
  //   trigger: 'blur',
  // }
})

// watch(() => props.formData.account_type, () => {
//   props.formData.bank_card_type = ''
// }, {
//   immediate: true
// }
// )

// const countDown = ref(60) // 倒计时60s
// const isCountDown = ref(false)

// 获取短信验证码
// const getAuthCode = () => {
//   formDataRef.value
//     .validateField('corprate_mobile')
//     .then(async () => {
//       try {
//         await getBankSms({ phone: props.formData.corprate_mobile })
//         ElMessage.success('验证码已发送，请注意查收')
//         isCountDown.value = true
//         getCountDown()
//       } catch (error) {}
//     })
//     .catch((err) => console.log('err', err))
// }

// 倒计时
// const getCountDown = () => {
//   let timer = null
//   let startTime = 60
//   timer = setInterval(() => {
//     startTime--
//     countDown.value = startTime
//     if (startTime <= 0) {
//       clearInterval(timer)
//       isCountDown.value = false
//     }
//   }, 1000)
// }

// 查询银行编码
const handleBankCode = () => {
  console.log(props.formData.bank_account_type, props.formData.account_type)
  if (
    props.formData.bank_account_type === 'ENTERPRISE_ACCOUNT' &&
    props.formData.account_type !== 3
  ) {
    return
  }
  formDataRef.value
    .validateField('bank_card_no')
    .then(() => {
      getBankCode({ bank_card_no: props.formData.bank_card_no }).then((res) => {
        Object.assign(props.formData, res)
      })
    })
    .catch((err) => {
      console.log('handleBankCode :>> ', err)
    })
}

const subBankNameFilterVal = ref('')
const subBankNameLoadAll = ref(false)
const getSubBankList = async () => {
  const subBankListLength = subBankList.value.length
  const id = subBankListLength ? subBankList.value[subBankListLength - 1].id : 0
  const bank_of_deposit = subBankNameFilterVal.value
  const res = await queryMiddleSubBankList({ id, bank_of_deposit })
  console.log(res)
  subBankList.value = [...subBankList.value, ...res.pay_bank_branch_info_vo_list]
  subBankNameLoadAll.value = res.pay_bank_branch_info_vo_list.length < 100
  console.log(subBankList.value)
}

const debounce = (cb, delay) => {
  let timer
  return function(...args) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      cb.call(this, ...args)
    }, delay)
  }
}

const subBankNameFilter = debounce(value => {
  if (value) {
    subBankList.value = []
    subBankNameFilterVal.value = value
    getSubBankList()
  }
}, 1000)

const loadmoreSubBankName = (v) => {
  console.log('触底的时候触发loadmore')
  console.log(v)
  if (!subBankNameLoadAll.value)getSubBankList()
}

const subBankNameChange = v => {
  console.log('subBankNameChange')
  console.log(v)
  myFormData.value.sub_bank_name = v.bank_of_deposit
  myFormData.value.bank_number = v.bank_of_deposit_code
}

defineExpose({
  validate: () => {
    return formDataRef.value.validate()
  }
})
</script>

<style lang="scss" scoped>
  .el-select{
    width: 100%;
  }
</style>

<!--
 * @Author: goodLiang
 * @Date: 2023-02-19 15:15:25
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-08 19:13:04
 * @FilePath: /vue4-template/src/views/enter/components/selectType.vue
-->
<template>
  <div class="panel-body">
    <div class="sub-heading m-t-n">
      <div class="sub-heading__title">个人店</div>
      <div class="sub-heading__desc">
        （适合个人/个体工商户入驻，提供身份证等即可开店）
      </div>
      <div class="sub-heading__desc">
        （适合个人入驻，提供身份证等即可开店）
      </div>
    </div>
    <div class="type">
      <div
        class="type-item"
        :class="{ active: formData.account_type == 3 }"
        @click="changeCustomerType(3)"
      >
        <div class="type-item__hd">
          <div class="name">个人店</div>
          <div class="desc">无营业执照，想以个人身份开店</div>
        </div>
        <div class="type-item__fd">
          <img src="../../../assets/personal_icon.png" alt="" />
        </div>
        <img src="../../../assets/select_type_icon.png" class="badge" />
      </div>
      <div
        class="type-item"
        :class="{ active: formData.account_type == 2 }"
        @click="changeCustomerType(2)"
      >
        <div class="type-item__hd">
          <div class="name">个体工商户</div>
          <div class="desc">有营业执照</div>
        </div>
        <div class="type-item__fd">
          <img src="../../../assets/personal_icon.png" alt="" />
        </div>
        <img src="../../../assets/select_type_icon.png" class="badge" />
      </div>
    </div>
  </div>
  <div class="panel-body">
    <div class="sub-heading m-t-n">
      <div class="sub-heading__title">企业店</div>
      <div class="sub-heading__desc">
        （适合企业入驻，提供企业营业执照等即可开店）
      </div>
    </div>
    <div class="type">
      <div
        class="type-item"
        :class="{ active: formData.account_type == 1 }"
        @click="changeCustomerType(1)"
      >
        <div class="type-item__hd">
          <div class="name">普通店</div>
          <div class="desc">有营业执照，想以企业为主体开店</div>
        </div>
        <div class="type-item__fd">
          <img src="../../../assets/enterprise_icon.png" alt="" />
        </div>
        <img src="../../../assets/select_type_icon.png" class="badge" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineExpose } from 'vue'
import { ElMessage } from 'element-plus'
const props = defineProps({
  formData: {
    type: Object,
    default: () => {}
  }
})

const myFormData = computed(() => props.formData)

const changeCustomerType = (val) => {
  myFormData.value.account_type = val
}

defineExpose({
  validate: () => {
    return new Promise((resolve, reject) => {
      if (myFormData.value.account_type !== '') {
        resolve()
      } else {
        ElMessage.error('请选择你所要开的门店')
        reject(new Error('请选择你所要开的门店'))
      }
    })
  }
})
</script>

<style lang="scss" scoped>
.type {
  width: 100%;
  display: flex;

  &-item {
    width: 460px;
    box-sizing: border-box;
    padding: 30px;
    border: 2px solid #f0f5fa;
    background: #f0f5fa;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .badge {
      width: 28px;
      height: 28px;
      position: absolute;
      bottom: -2px;
      right: -2px;
      display: none;
    }

    &.active {
      border: 2px solid #0071db;

      .badge {
        display: block;
      }
    }

    &__hd {
      flex: 1;

      .name {
        font-size: 16px;
        font-weight: bold;
      }

      .desc {
        margin-top: 12px;
        color: #484d52;
      }
    }

    &__fd {
      margin-left: 20px;

      img {
        width: 54px;
        height: 54px;
      }
    }
  }

  &-item + &-item {
    margin-left: 20px;
  }
}
</style>

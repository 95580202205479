<!--
 * @Author: goodLiang
 * @Date: 2023-02-19 17:34:51
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-08 18:52:58
 * @FilePath: /vue4-template/src/views/enter/components/agreementText.vue
-->
<template>
  <div class="panel-heading">
    <div class="panel-heading__title">签署合同</div>
  </div>
  <div class="panel-body">
    <div class="agreement">
      <div class="agreement-title">歌德平台卖家服务协议</div>
      <div class="agreement-body">
        <div class="agreement-body__text">
          <div>
            <!-- <p style="margin:0pt; orphans:0; text-align:center; widows:0"><span
                  style="font-family:微软雅黑; font-size:14pt; font-weight:bold">歌德</span><span
                  style="font-family:微软雅黑; font-size:14pt; font-weight:bold">平台</span><span
                  style="font-family:微软雅黑; font-size:14pt; font-weight:bold">卖家</span><span
                  style="font-family:微软雅黑; font-size:14pt; font-weight:bold">服务</span><span
                  style="font-family:微软雅黑; font-size:14pt; font-weight:bold">协议</span></p> -->
            <p
              style="
                line-height: 10.5pt;
                margin: 0pt 0pt 10pt;
                text-align: justify;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10.5pt"
                >本协议由您</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">（</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >以下或称“卖家”</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">）</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">与</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">歌德</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >交易平台</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">（</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">即</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >新疆歌德优选信息科技有限公司及其分公司</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">）</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >之间依据《中华人民共和国</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >民法典</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >》及相关法律法规就您使用</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"> </span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">歌德</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"> </span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >交易平台服务等相关事宜所订立契约。</span
              >
            </p>
            <p
              style="
                line-height: 10.5pt;
                margin: 0pt 0pt 10pt;
                text-align: justify;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10.5pt"
                >在接受本协议、使用</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">歌德</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >交易平台</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">(</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >以下简称“</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >”或“歌德</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">”</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt">)</span
              ><span style="font-family: 微软雅黑; font-size: 10.5pt"
                >服务之前，请您仔细阅读全部条款内容，特别是用粗体标识的条款，您需要重点阅读。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >【特别提示】如您点击同意接受本协议即视为您同意受本协议约束使用</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >歌德</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >服务，并认可</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >歌德</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >的交易模式。如您对本协议的条款有疑问，请通过</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >歌德</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >的客服进行咨询，</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >客服</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >将向您解释条款内容。如您不同意本协议内容，请不要进行后续操作；如您继续使用</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >歌德</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10.5pt;
                  font-weight: normal;
                "
                >服务，视为您同意本协议。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 0pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt">1、</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >第一条相关定义及解释</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">：</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">指</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">歌德</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >平台、其关联网站、App应用
                (含现有及将来的桌面端、移动端APP)、小程序及其他形式的网络平台或客户端。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">用户</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">：</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >指所有在平台上注册成为会员的法人、自然人及其他组织，本协议中统称为“用户”，除非另有说明，否则“用户</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">“</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >均指此含义。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">：</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >平台用户中为平台其他用户提供商品的认证企业或符合法律法规规定的个人，包括但不限于</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >个人店、个体工商户、企业店</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">。</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >家依据入驻流程和要求完成在线信息提交，经</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >审核同意后，使用</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >家自行设定的平台用户名和密码登陆平台，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">建立</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >线上商店，开展经营服务。本协议中的</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >家指本协议缔约方中的“乙方”</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">。</span>
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >二、入驻条件及证明文件提交</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >1、卖家入驻流程</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">：</span>
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >填写信息</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">→</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >入驻审核</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">→</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >签署合同</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">→</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >支付保证金</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">→</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >入驻成功</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >1.1个人卖家填写内容：店铺名称、身份证正反面照片、姓名、身份证号、身份证件有效期、平台结算账户信息；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >1.2个体工商户填写内容：店铺名称、身份证正反面照片、联系人手机号码、联系人邮箱、企业名称、营业执照、统一社会信用代码、营业执照起始日期、平台结算账户信息；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >1.3企业店（</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >暂时只支持境内企业入驻</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >）填写内容：店铺名称、身份证正反面照片、联系人手机号码、联系人邮箱、企业名称、营业执照、统一社会信用代码、营业执照起始日期、平台结算账户信息。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 0pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt">2、</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >店铺名称规则：</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.1.店铺名称不得与已经通过审核的店铺名称重复。若两个或以上店铺申请相同的符合规定的店铺名称，平台依照申请在先原则核定，未通过审核的店铺需要更换店铺名称后重新提交申请。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.2未经平台许可，店铺名称、店铺标识、店铺简介等均不得使用“歌德特许”、“歌德授权”或其他带有类似含义的内容。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.3未经相关权利人授权并提交有效证明文件，店铺名称、店铺标识、店铺简介等均不得使用“特约经营”、“特约经销”、“总经销”、“总代理”、“厂家认证”、“官方店铺”或其他可能是买家误认为该店铺已经取得相应品牌授权的内容。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.4个人店铺的店铺名称不得使用“旗舰”、“专卖”、“专营”、“官方”、“直营”、“官字”、“官方认证”、“官方授权”、“特许经营”、“特约经销”或其他带有类似含义的内容。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.5店铺名称不得带有电话号码、电子邮箱、网址、二维码、即时通讯工具或其他联系信息（如QQ号码、微信号等）。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.6店铺名称不得包含具有下列情形的内容：</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.6.1有损国家、社会公共利益，或有损民族尊严的；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.6.2侵犯他人合法权利的；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.6.3夸大宣传、可能误导公众的；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.6.4外国国家或地区的名称；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.6.5国际组织的名称；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.6.6政治敏感信息，包括但不限于国家领导人姓名、政党名称、党政军机关名称；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.6.7含有封建文化糟粕、有消极政治影响，或违背少数民族习俗、带有歧视性的；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.6.8与经营主体无关，含有除歌德外的其他电子商务平台信息；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >2.6.9其他违反法律法规或社会善良风俗的。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >上述文件应获得歌德平台的审核通过。同时，平台有权对卖家提交的文件随时进行进一步核查，并保留在申请入驻以及后续经营阶段要求卖家提交其他资质或文件供平台审核的权利。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >卖家保证向平台提供的上述证明文件或其他相关证明真实、合法、准确、有效，并保证上述证明文件或其他相关证明发生任何变更或更新时，须立即通知平台，并于【5】个工作日内，向平台提交更新后的文件。若卖家未及时向平台通知上述变更或更新事宜、未于上述时限内向平台提交更新后的文件，或上述文件变更或更新导致卖家不符合本协议及平台规则所规定入驻条件的，平台有权单方面全部或部分限制卖家经营，直至终止本协议。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >卖家对其提交的证明文件或其他相应证明的真实性、合法性、准确性、有效性承担全部法律责任，若因卖家提交虚假、过期文件或未及时更新或通知证明文件导致纠纷或被相关国家机关处罚的，由卖家独立承担全部法律责任，如因此造成歌德平台损失的，卖家应予以赔偿。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >卖家了解并同意，当市场监督管理部门或税务部门等国家机关或机构要求歌德平台提供卖家的身份信息和/或与纳税有关的信息时，平台将依照上述部门或机关的要求提供或报送该等信息。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 20pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >卖家进一步确认，如在卖家入驻后，相关法律法规、国家机关或平台要求其满足额外的资质条件，其应予以遵守，否则平台有权采取相关措施，包括但不限于单方面限制卖家继续在平台进行经营，直至终止本协议。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 20pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >平台为卖家开通服务后，卖家可利用申请的店铺名称及自设密码登陆商家管理后台，根据平台相关规则及流程发布商品价格信息，使用本协议约定的其他服务等。卖家应妥善保管账户密码等信息，其账户不得以任何形式转租、出借、转让或允许第三方使用，通过卖家店铺名及密码登录商家管理后台所做的任何行为均被视为是卖家或卖家授权的行为，卖家应对该等行为独立承担所有后果及责任。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >三、双方权利及义务</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">3</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">.</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">1</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >在使用平台进行交易的过程中必须遵守诚信交易的原则：</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >如果用户或其他第三方针对上述情况根据平台规则进行投诉</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">且</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >基于普通知识水准人员的独立判断或在</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >认为必要的情况下基于独立第三方所提供或出具的相关专业意见认为该投诉成立，则对于实际发生于</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >内的交易，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >有权立即从</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >的保证金及未结算货款中直接扣划相应金额对受影响的用户或其他第三方进行赔付，赔付的金额为“买家用户实际支付的商品价款*4（即退一赔三）”，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">对</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >的上述判断、核实、处理方式不持异议。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">3</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >.2商家信息和商品发布</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >卖家有义务对拟通过平台出售或交易给用户的全部商品的质量进行事先详细核查，若发现相关商品存在任何瑕疵，应暂缓寄往平台，停止出价销售，避免售后纠纷。如因卖家未履行核查义务，平台收到后核查后商品审核不通过，将退回卖家，并处以</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >罚没保证金处罚。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 0pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt">4、</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >保证金</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >需在入驻时向</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >缴纳一定金额的保证金，作为履行本协议、消费者权益保障义务及遵守</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台商家服务协议</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >的保证。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt">4.1卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >同意平台有完全的权利根据</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >的合理判断，在</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >违反法律、法规、政策或违反其对</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">用户</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >的承诺，消费者权益保障义务或本协议以及平台规则时，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >有权直接扣除相应金额的保证金作为违约金或作为给予买家的赔付，当保证金不足且</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >未补足时，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >有权直接根据第</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">4.5</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">条从</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >冻结货款中转入补足保证金，同时</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">保留就</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >的上述违规行为继续追偿的权利。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >4.2</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >保证金额度：商家保证金不低于</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >壹</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >万元人民币，</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >个人</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >店保证金额度不低于贰</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >千</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >人民币，</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >卖家</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >需按照</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >歌德平台</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >要求的时间及方式支付足额保证金至</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >歌德平台</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >指定账户。</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: bold;
                "
                >本期业务上线暂时减免保证金，卖家0元可入驻平台。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt">4.3</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >乙方同意缴纳的保证金在任何情况下，均不计算任何利息。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt">4.4</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >保证金补足：当乙方进行货款提现时，若保证金账户金额不足，需优先补足保证金才能提现。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >4.5 歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >如使用保证金进行任何抵扣或赔付，将以书面方式（包括但不限于电子邮件、微信、QQ、传真等）通知</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >，并在通知中说明抵扣和/或赔付原因及抵扣和/或赔付金额，且</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >同意在保证金不足抵扣及赔付或保证金账户金额未补足时，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">将冻结</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >未结算货款，在保证金补足后方可进行提现。如在</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">冻结</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >未结算货款后5个工作日内，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >仍未对保证金进行补足，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >有权直接自冻结货款中进行补足。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt">4.6</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >保证金的退还：</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >将按照在店铺关闭手续办理完成后向</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >退还保证金，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >将扣除依据本协议和</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">相关</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >规则应扣除的部分，而后将保证金余额退还</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">，如</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >支付的保证金不足以抵扣应由</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >支付的违约金、赔偿金等款项的，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >将不予退还</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >保证金，并保留进一步追偿的权利。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >五、费用及结算</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">须向</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >提供其在</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >指定的第三方支付公司开设的结算账户或银行账户以便完成</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">货</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >款结算，若</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >账户信息变更，应</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >在后台提交结算账户变更</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >，否则，因</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >变更账户造成货款支付失败、迟延或错误，损失由</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >自行承担。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <ins
                style="
                  -aw-revision-author: 'zxy';
                  -aw-revision-datetime: '2023-02-20T14:02:51';
                "
                ><span style="font-family: 微软雅黑; font-size: 10pt"
                  >用户在平台确认收货次日起7日后（遇节假日或休息日顺延至节假日或休息日后的第一个工作日），平台依据结算周期自主生成结算单，卖家确认结算单无误，审核通过，平台依据卖家审核无误的货款金额进行核对、确认后依据该金额进行结算；卖家可不对当期结算单进行确认并累计结算账期进行确认结算。</span
                ></ins
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >六、物流</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >卖家提交实物鉴别后，歌德酒市可提供上门取货服务，卖家需按照实际情况填写取件地址与取件时间。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >6.1</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >卖家出售商品需使用歌德酒市指定物流顺丰速运，寄至歌德酒市进行实物鉴别，若商品达到上架标准，平台承担上门取货的物流费用</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >6.2</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >出售的商品（含部分）与描述不符或不符合签收标准，平台将拒收，商品原路返回，因此产生的全部物流费用由卖家承担</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >6.3</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >出售商品(含部分)未满足出售标准，平台将采取‘保价到付’方式寄回，因此产生的全部物流费用由卖家承担</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >6.4</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >卖家提交实物鉴别且预约物流后，应在物流取件时根据图片鉴别的估价进行保价，平台不接受高于图片鉴别估价的保价，若卖家保价金额高于图片鉴别的估价，平台将从卖家提交的保证金内扣除保价费差额，如遇保证金不足的情况，需卖家补缴或从销售产生的货款中扣除；若卖家保价过低或不保价，途中产生快递风险或其他损失将由卖家自行承担。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >6.5</span
              ><span
                style="
                  font-family: 微软雅黑;
                  font-size: 10pt;
                  font-weight: normal;
                "
                >若待鉴别商品在运输途中产生破碎等情况，平台有权拒收商品，卖家需自行承担快递保价费用，并与物流协商赔偿，在此过程中平台可协助，但不承担因此而产生的损失及理赔责任。待鉴别商品在寄来平台的快递运输过程中产生品相破损，但仍符合售卖条件，平台将与卖家联系，询问是否同意降价销售并接受差价损失；如不接受差价损失，卖家可选择协商平台退回；期间产生的任何费用和损失（包括但不限于差价损失、运费）由卖家自行与快递公司协商赔偿，平台不负责理赔事宜。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">七、</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >平台收费</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt">对于</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家已</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >达成的交易，在订单交易成功后，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">将按照</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">用户</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >实际支付的金额收取平台交易服务费（具体详见《</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">歌德</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >交易服务协议》）</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">八、</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >协议有效期</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">8.1</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >本协议有效期自</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >点击同意之日起至&nbsp;&nbsp; 202</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">3</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">
                年12 月&nbsp; 31
                日止。协议有效期结束，并不影响在协议有效期内双方已确定的义务之履行。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">8.2</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >本协议到期后，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >继续使用</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >服务，并在登录时点击阅读并同意本协议的，则表示双方达成一致同意续展本协议，并</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">接受</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >届时点击同意的协议版本继续履行各自权利义务；若</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >不同意届时本协议中的任何条款，可以不予点击同意，则</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >不再使用</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >提供的任何服务，本协议在到期日终止。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">九、</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >协议的终止</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">9.1</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >本协议在下述情况下自然终止：</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">9.1.1</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >店铺服务期届满，而</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >未提出续签申请或续签申请未通过</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >审核的；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">9.1.2</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >协议方签署新协议替代本协议的。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">9.</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">2若</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >基于自身运营原因，主动提出终止协议的，应至少提前5个工作日向</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >提出申请，经</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >审核同意后，店铺按本协议约定的程序完成退店后，协议终止。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">9</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >.3 出现以下任一情形时，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >有权随时停止为</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >提供服务，并有权同时采取本协议或平台规则规定的其他措施：</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">9</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">.3.1</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >提供虚假资质文件的；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">9</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">.3.2 </span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >不再符合入驻资质要求，或其提供的入驻资质文件过期，但后续未能按时提供有效的、合法的资质文件的；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">9</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">.3.3 </span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >销售或交易的商品质量、标识不合格的；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">9</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >.3.4通过不正当手段使用</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >注册LOGO、域名等用于不正当宣传、商业活动，给消费者造成误导、侵犯</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >知识产权等给</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >造成不良影响的行为；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">9</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >.3.5
                乙方扰乱平台正常经营秩序，在线上、线下等渠道，通过文字或图片等方式发布恶意诋毁、故意矮化</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">歌德</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >平台或出现负面消息影响平台商誉的；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">十、</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >协议的变更</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >若对本协议作出修改，会按照相关法律规定进行公示及征求意见。在修改内容实施后，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >首次登陆时平台将提醒</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >相关修改，并需</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >点击同意修改后的协议；如</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >不接受修改内容、申请退出平台，</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >同意其退出。</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >在此确认，其在平台线上确认接受该等修改后的规则，则视为</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >已同意、并接受该等变更后规则的约束，无论事后双方是否就此签署纸质补充或变更协议予以再次确认，均不影响该等变更后规则对</span
              ><span style="font-family: 微软雅黑; font-size: 10pt">卖家</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >的约束力。</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <br /><span style="font-family: 微软雅黑; font-size: 10pt"
                >请确认您已经仔细阅读《</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >歌德平台卖家服务</span
              ><span style="font-family: 微软雅黑; font-size: 10pt"
                >协议》及其附件的相关内容，同意遵守协议的相关约定。（以下无正文）</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">甲方：</span>
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 微软雅黑; font-size: 10pt">乙方：</span>
            </p>
            <div
              id="extwaiokist"
              style="display: none"
              v="efmeb"
              q="5bb8aace"
              c="694.2"
              i="707"
              u="29.17"
              s="02252318"
              d="1"
              w="false"
              e=""
              a="2"
              m="BMe="
              vn="9axur"
            >
              <div
                id="extwaigglbit"
                style="display: none"
                v="efmeb"
                q="5bb8aace"
                c="694.2"
                i="707"
                u="29.17"
                s="02252318"
                d="1"
                w="false"
                e=""
                a="2"
                m="BMe="
              ></div>
            </div>
          </div>
          <div>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >重要须知：</span
              >
            </p>
            <p
              style="
                margin: 0pt;
                orphans: 0;
                text-align: justify;
                text-indent: 21pt;
                widows: 0;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >在适用的法律法规允许的最大限度内，</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >有权根据情况不时地制定、修订、调整及</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >或变更本协议正文及</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >或附件，并将提前至少</span
              ><span style="font-family: Calibri; font-size: 10.5pt">7</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >日平台公示并通知</span
              ><span style="font-family: 宋体; font-size: 10.5pt">卖家</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >。前述制定、修订、调整及</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >或变更后的本协议正文及</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt">或附件经</span
              ><span style="font-family: Calibri; font-size: 10.5pt">7</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >日公示期满且经</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt">通知</span
              ><span style="font-family: 宋体; font-size: 10.5pt">卖家</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >后，立即自动生效，成为本协议的一部分；虽有前述约定，本协议生效后，如</span
              ><span style="font-family: 宋体; font-size: 10.5pt">卖家</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >不同意前述制定、修订、调整及</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >或变更后的本协议正文及</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >或附件，可自甲方平台公示并通知卖家之日起</span
              ><span style="font-family: Calibri; font-size: 10.5pt">3</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >个工作日内以通知方式（包括但不限于书面函件、</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >向平台客服反馈</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >）向甲方提出异议，在此种情况下，卖家应与</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >协商本协议终止事宜，且甲方</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >对于该等终止不负有任何违约责任或其他责任。如卖家未根据前述约定向甲方提出异议，即视为卖家接受前述制定、修订、调整及</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >或变更后的本协议正文及</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >或附件；如卖家登录或继续使用</span
              ><span style="font-family: 宋体; font-size: 10.5pt">歌德平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >（包括但不限于商家账户，下同），即视为卖家接受前述制定、修订、调整及</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >或变更后的本协议正文及</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >或附件。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 宋体; font-size: 10.5pt">一、</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >资费项目名称解释说明</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">1</span
              ><span style="font-family: 宋体; font-size: 10.5pt">、</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >针对正常买卖成功的订单，</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >仅对卖家收取佣金、商品鉴酒费用、</span
              ><span style="font-family: 宋体; font-size: 10.5pt">仓储</span
              ><span style="font-family: 宋体; font-size: 10.5pt">存酒费</span
              ><span style="font-family: 宋体; font-size: 10.5pt">和物流</span
              ><span style="font-family: 宋体; font-size: 10.5pt">运费，</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >提现金额及</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >支付通道费并非平台收取。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">1.1</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >佣金：在平台进行交易过程中，所产生的服务费用</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >。卖家应向平台支付佣金费用</span
              ><span style="font-family: Calibri; font-size: 10.5pt">=</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >订单（交易成功的订单）的商品金额×相应品类价格区间的费率，</span
              ><span
                style="color: #ff0000; font-family: 宋体; font-size: 10.5pt"
                >具体费用类别、数额如下表所示；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">1.2</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >鉴酒费：用于货品的实物鉴别费用，</span
              ><span
                style="font-family: 宋体; font-size: 10.5pt; font-weight: bold"
                >本期业务上线暂时减免</span
              ><span style="font-family: 宋体; font-size: 10.5pt">；</span>
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">1.3</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >存酒费：商品存储在歌德平台酒库，以及相关业务的存储库房中，所产生的存酒费用，</span
              ><span
                style="font-family: 宋体; font-size: 10.5pt; font-weight: bold"
                >本期业务上线暂时减免</span
              ><span style="font-family: 宋体; font-size: 10.5pt">；</span>
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">1.4</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >运费：包括但不限于商品在寄送过程中，物流、快递运输及保价费用，破碎、丢失、破损等损失。具体标准为：卖家寄送至平台不收取运费，若卖家有违规行为、未销售提酒或酒品经鉴定为假酒的，则往返运费均由卖家承担。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">1.5</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >支付通道费：卖家在歌德</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >进行交易过程中所产生的支付通道手续费，按相关平台实时政策酌情收取，约为</span
              ><span style="font-family: Calibri; font-size: 10.5pt">2.5</span
              ><span style="font-family: 宋体; font-size: 10.5pt">‰至</span
              ><span style="font-family: Calibri; font-size: 10.5pt">6</span
              ><span style="font-family: 宋体; font-size: 10.5pt">‰</span>
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">1.6</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >钱包提现金额：卖家在歌德平台交易后从钱包提现所产生的手续费，按相关平台实时政策酌情收取，约为</span
              ><span
                style="color: #ff0000; font-family: 宋体; font-size: 10.5pt"
                >‰至‰</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">2</span
              ><span style="font-family: 宋体; font-size: 10.5pt">、</span
              ><span style="font-family: 宋体; font-size: 10.5pt">说明：</span>
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">2.1</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >交易成功的订单：</span
              ><span style="font-family: 宋体; font-size: 10.5pt">用户</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >按照平台的流程和规则在平台成功下单、付款，</span
              ><span style="font-family: 宋体; font-size: 10.5pt">卖家</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >按照平台规则发货，</span
              ><span style="font-family: 宋体; font-size: 10.5pt">用户</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >成功收到相应货品且订单状态处于待评价或交易完成的订单。</span
              ><span style="font-family: 宋体; font-size: 10.5pt">歌德平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >将向交易成功的订单收取相应的平台交易服务费；</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">2.2</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >交易失败的订单：凡是不符合“交易成功订单”要求的订单均属交易失败订单；</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >不会就交易失败的订单收取平台交易服务费。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">2.3</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >上述费用的支付时间和方式：</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >在订单用户签收时，</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt">从</span
              ><span style="font-family: 宋体; font-size: 10.5pt">卖家</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >的可提现账户予以扣除相应的平台交易服务费。详细信息可在</span
              ><span style="font-family: 宋体; font-size: 10.5pt">卖家</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >可提现账户中查阅相关的订单流水信息。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 宋体; font-size: 10.5pt">二、</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >协议的暂停与终止</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">2.1 </span
              ><span style="font-family: 宋体; font-size: 10.5pt">除原协议</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本协议另有明确约定外，如卖家违反原协议</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本协议项下的任何约定，</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >有权立刻终止本协议，且按原协议</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本协议的相关规定采取相关处理措施。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">2.</span
              ><span style="font-family: Calibri; font-size: 10.5pt">2</span
              ><span style="font-family: Calibri; font-size: 10.5pt"> </span
              ><span style="font-family: 宋体; font-size: 10.5pt">除原协议</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本协议另有约定外，原协议</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本协议规定的其他协议终止条件发生或实现时，本协议终止。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">2.</span
              ><span style="font-family: Calibri; font-size: 10.5pt">3</span
              ><span style="font-family: 宋体; font-size: 10.5pt">除原协议</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本协议另有约定外，无论本协议因何种原因终止，在本协议终止前的卖家行为所导致的任何处罚、赔偿和责任，卖家必须完全且独立地承担。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">2.</span
              ><span style="font-family: Calibri; font-size: 10.5pt">4</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本协议某一条款的无效，不影响本协议其他条款的效力。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 宋体; font-size: 10.5pt">三、</span
              ><span style="font-family: 宋体; font-size: 10.5pt">其他</span>
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">3.1</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本协议取代双方就有关本协议所载任何事项于先前以口头及书面达成的共识。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">3.2</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >向卖家收取交易服务费并不代表对卖家的工商注册登记状况的合法性作出任何持续性的认可或保证，如卖家不符合届时有效或经立法机关、主管部门以任何形式释明的工商登记要求，</span
              ><span style="font-family: 宋体; font-size: 10.5pt">平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >有权随时停止向卖家提供交易服务并根据原协议</span
              ><span style="font-family: Calibri; font-size: 10.5pt">/</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本协议的约定采取相应处理措施，且无需承担任何违约责任。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">3.3</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本协议之解释与适用，以及与本协议及附则有关的争议，均应依照中华人民共和国法律予以处理，在履行本协议过程中产生的任何争议，双方应根据平台规则协商解决。协商不成的，应提交北京仲裁委员会按照该会仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">3.4</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">3.</span
              ><span style="font-family: Calibri; font-size: 10.5pt">5</span
              ><span style="font-family: 宋体; font-size: 10.5pt">您对</span
              ><span style="font-family: 宋体; font-size: 10.5pt">歌德平台</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >的收费政策不予认可，请立即停止使用服务。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt">3.</span
              ><span style="font-family: Calibri; font-size: 10.5pt">6</span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >本补充协议与原协议具有同等法律效力，若两者内容冲突的，以本补充协议为准；本补充协议未约定的内容，仍按原协议履行。</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >（以下无正文）</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 宋体; font-size: 10.5pt">甲方：</span>
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 宋体; font-size: 10.5pt">乙方：</span>
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >签署日期：</span
              >
            </p>
            <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
              <span style="font-family: Calibri; font-size: 10.5pt"
                >&nbsp;</span
              >
            </p>
            <div
              id="extwaiokist"
              style="display: none"
              v="efmeb"
              q="5bb8aace"
              c="694.2"
              i="707"
              u="29.19"
              s="02252318"
              d="1"
              w="false"
              e=""
              a="2"
              m="BMe="
              vn="9axur"
            >
              <div
                id="extwaigglbit"
                style="display: none"
                v="efmeb"
                q="5bb8aace"
                c="694.2"
                i="707"
                u="29.19"
                s="02252318"
                d="1"
                w="false"
                e=""
                a="2"
                m="BMe="
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement-checkout">
      <el-checkbox v-model="myFormData.isAgreement" name="isAgreement"
        >我已阅读并同意以上协议</el-checkbox
      >
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineExpose } from 'vue'
import { ElMessage } from 'element-plus'
const props = defineProps({
  formData: {
    type: Object,
    default: () => {}
  }
})

const myFormData = computed(() => props.formData)

defineExpose({
  validate: () => {
    return new Promise((resolve, reject) => {
      if (myFormData.value.isAgreement) {
        resolve('success')
      } else {
        ElMessage.error('请阅读并同意商户入住协议')
        reject(new Error('error'))
      }
    })
  }
})
</script>

<style lang="scss" scoped>
.agreement {
  padding: 30px;
  background: #f2f8fc;

  &-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
  }

  &-body {
    height: 300px;
    overflow-y: auto;

    &__text {
      color: #484d52;
    }
  }

  &-checkout {
    margin: 20px auto -10px;
    text-align: center;
  }
}
</style>

/*
 * @Author: goodLiang
 * @Date: 2023-02-21 13:28:45
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-08 16:15:13
 * @FilePath: /vue4-template/src/views/enter/rules.js
 */
import test from '../../utils/test'
export const validatorIdCard = () => {
  return (rule, value, callback) => {
    if (test.idCard(value)) {
      callback()
    } else {
      callback(new Error('请输入正确正确的身份证号码'))
    }
  }
}

// 手机号验证
export const validatorMobile = () => {
  return (rule, value, callback) => {
    if (test.mobile(value)) {
      callback()
    } else {
      callback(new Error('请输入正确的手机号'))
    }
  }
}

// 手机号验证
export const validatorEmail = () => {
  return (rule, value, callback) => {
    if (test.email(value)) {
      callback()
    } else {
      callback(new Error('请输入正确的联系人邮箱'))
    }
  }
}

// 银行卡验证
export const validatorBankNo = () => {
  return (rule, value, callback) => {
    if (test.checkBankNo(value) && value !== '') {
      callback()
    } else {
      callback(new Error('请输入正确的结算银行账户'))
    }
  }
}

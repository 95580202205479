<!--
 * @Author: goodLiang
 * @Date: 2023-02-15 17:16:52
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-01-03 13:54:46
 * @FilePath: /business-cms/src/views/login/index.vue
-->
<template>
  <div class="login">
    <div class="login-main">
      <div class="login-main__left"></div>
      <div class="login-main__right" v-if="loginView === 1">
        <el-form
          ref="loginFormRef"
          :model="loginForm"
          :rules="loginRules"
          autocomplete="on"
          style="height: 100%"
        >
          <div class="login-body">
            <div class="login-title">
              您好！<br />
              欢迎来到歌德商家工作台
            </div>
            <div class="login-form">
              <el-form-item prop="account">
                <div class="form-item">
                  <div class="form-item__icon">
                    <img
                      src="../../assets/mobile_default.png"
                      class="icon-default"
                      alt=""
                    />
                    <img
                      src="../../assets/mobile_active.png"
                      class="icon-active"
                      alt=""
                    />
                  </div>
                  <el-input
                    type="text"
                    name="account"
                    placeholder="请输入手机号"
                    maxlength="11"
                    autocomplete="on"
                    v-model="loginForm.account"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <div class="form-item">
                  <div class="form-item__icon">
                    <img
                      src="../../assets/password_default.png"
                      class="icon-default"
                      alt=""
                    />
                    <img
                      src="../../assets/password_active.png"
                      class="icon-active"
                      alt=""
                    />
                  </div>
                  <el-input
                    type="text"
                    name="password"
                    placeholder="请输入密码"
                    maxlength="12"
                    autocomplete="on"
                    v-model="loginForm.password"
                  ></el-input>
                </div>
              </el-form-item>
              <el-link type="primary" @click="loginView = 3">忘记密码</el-link>
            </div>
            <div class="login-action">
              <el-button
                type="primary"
                size="large"
                class="login-button"
                @click="submitLogin"
                :loading="loginLoading"
                >登 录</el-button
              >
              <div class="login-action__fd">
                <div class="i-text-gray"></div>
                <a class="tips" @click="loginView = 2">
                  <img
                    src="../../assets/add_small_icon.png"
                    class="tips-icon"
                    alt=""
                  />
                  <div class="i-text-primary i-bold i-font-14">申请入驻</div>
                </a>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="login-main__right" v-if="loginView === 2">
        <el-form
          ref="registerFormRef"
          :model="registerForm"
          :rules="registerRules"
          style="height: 100%"
        >
          <div class="login-body">
            <div class="register-title">境内商家入驻</div>
            <div class="login-form">
              <el-form-item prop="account">
                <div class="form-item">
                  <div class="form-item__icon">
                    <img
                      src="../../assets/mobile_default.png"
                      class="icon-default"
                      alt=""
                    />
                    <img
                      src="../../assets/mobile_active.png"
                      class="icon-active"
                      alt=""
                    />
                  </div>
                  <el-input
                    type="text"
                    name="account"
                    placeholder="请输入手机号"
                    maxlength="11"
                    v-model="registerForm.account"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <div class="form-item">
                  <div class="form-item__icon">
                    <img
                      src="../../assets/password_default.png"
                      class="icon-default"
                      alt=""
                    />
                    <img
                      src="../../assets/password_active.png"
                      class="icon-active"
                      alt=""
                    />
                  </div>
                  <el-input
                    type="text"
                    name="password"
                    minlength="6"
                    maxlength="10"
                    placeholder="请输入密码"
                    v-model="registerForm.password"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="repeat_password">
                <div class="form-item">
                  <div class="form-item__icon">
                    <img
                      src="../../assets/password_default.png"
                      class="icon-default"
                      alt=""
                    />
                    <img
                      src="../../assets/password_active.png"
                      class="icon-active"
                      alt=""
                    />
                  </div>
                  <el-input
                    type="text"
                    name="repeat_password"
                    minlength="6"
                    maxlength="10"
                    placeholder="请再次输入密码"
                    v-model="registerForm.repeat_password"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="security_code">
                <div class="form-item">
                  <div class="form-item__icon">
                    <img
                      src="../../assets/code_default.png"
                      class="icon-default"
                      alt=""
                    />
                    <img
                      src="../../assets/code_active.png"
                      class="icon-active"
                      alt=""
                    />
                  </div>
                  <el-input
                    type="text"
                    name="security_code"
                    maxlength="5"
                    placeholder="请输入验证码"
                    v-model="registerForm.security_code"
                  ></el-input>
                  <div class="code-btn i-text-gray" v-if="isCountDown">
                    {{ countDown }}s
                  </div>
                  <a
                    @click="getAuthCode(2)"
                    class="code-btn i-text-primary"
                    v-else
                  >
                    发送验证码
                  </a>
                </div>
              </el-form-item>
            </div>
            <div class="login-action">
              <el-button
                type="primary"
                size="large"
                class="login-button"
                @click="submitRegister(2)"
                :loading="registerLoading"
                >0元入驻</el-button
              >
              <div class="login-action__fd">
                <div class="i-text-gray"></div>
                <a class="tips" @click="loginView = 1">
                  <img
                    src="../../assets/return_small_icon.png"
                    class="tips-icon"
                    alt=""
                  />
                  <div class="i-text-gray i-bold i-font-14">返回登录</div>
                </a>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="login-main__right" v-if="loginView === 3">
        <el-form
          ref="registerFormRef"
          :model="registerForm"
          :rules="registerRules"
          style="height: 100%"
        >
          <div class="login-body">
            <div class="register-title">找回密码</div>
            <div class="login-form">
              <el-form-item prop="account">
                <div class="form-item">
                  <div class="form-item__icon">
                    <img
                      src="../../assets/mobile_default.png"
                      class="icon-default"
                      alt=""
                    />
                    <img
                      src="../../assets/mobile_active.png"
                      class="icon-active"
                      alt=""
                    />
                  </div>
                  <el-input
                    type="text"
                    name="account"
                    placeholder="请输入手机号"
                    maxlength="11"
                    v-model="registerForm.account"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="security_code">
                <div class="form-item">
                  <div class="form-item__icon">
                    <img
                      src="../../assets/code_default.png"
                      class="icon-default"
                      alt=""
                    />
                    <img
                      src="../../assets/code_active.png"
                      class="icon-active"
                      alt=""
                    />
                  </div>
                  <el-input
                    type="text"
                    name="security_code"
                    maxlength="5"
                    placeholder="请输入验证码"
                    v-model="registerForm.security_code"
                  ></el-input>
                  <div class="code-btn i-text-gray" v-if="isCountDown">
                    {{ countDown }}s
                  </div>
                  <a
                    @click="getAuthCode(3)"
                    class="code-btn i-text-primary"
                    v-else
                  >
                    发送验证码
                  </a>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <div class="form-item">
                  <div class="form-item__icon">
                    <img
                      src="../../assets/password_default.png"
                      class="icon-default"
                      alt=""
                    />
                    <img
                      src="../../assets/password_active.png"
                      class="icon-active"
                      alt=""
                    />
                  </div>
                  <el-input
                    type="text"
                    name="password"
                    minlength="6"
                    maxlength="10"
                    placeholder="请输入新密码"
                    v-model="registerForm.password"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="repeat_password">
                <div class="form-item">
                  <div class="form-item__icon">
                    <img
                      src="../../assets/password_default.png"
                      class="icon-default"
                      alt=""
                    />
                    <img
                      src="../../assets/password_active.png"
                      class="icon-active"
                      alt=""
                    />
                  </div>
                  <el-input
                    type="text"
                    name="repeat_password"
                    minlength="6"
                    maxlength="10"
                    placeholder="请确认新密码"
                    v-model="registerForm.repeat_password"
                  ></el-input>
                </div>
              </el-form-item>
            </div>
            <div class="login-action">
              <el-button
                type="primary"
                size="large"
                class="login-button"
                @click="submitRegister(3)"
                :loading="registerLoading"
                >提交</el-button
              >
              <div class="login-action__fd">
                <div class="i-text-gray"></div>
                <a class="tips" @click="loginView = 1">
                  <img
                    src="../../assets/return_small_icon.png"
                    class="tips-icon"
                    alt=""
                  />
                  <div class="i-text-gray i-bold i-font-14">返回登录</div>
                </a>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="login-footer i-text-gray">
      <!-- <div>Copyright 2019-2020 All Rights Reserved.</div> -->
      <div>版权所有新疆歌德优选信息科技有限公司</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;新ICP备2021000477号</div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { loginRules, registerRules } from './rules'
import {
  smsCenter,
  registerUser,
  smsCenterFind,
  updatePopSellerPassWord
} from '../../api/user.js'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import md5 from 'md5'

const loginView = ref(1) // 1登录 2注册 3找回密码
const store = useStore()
const router = useRouter()
const loginLoading = ref(false)

// 登录
const loginFormRef = ref()
const loginForm = ref({
  account: '',
  password: ''
})

const submitLogin = () => {
  loginFormRef.value
    .validate()
    .then(() => {
      loginLoading.value = true
      store
        .dispatch('user/login', loginForm.value)
        .then(async (res) => {
          loginLoading.value = false
          router.push('/')
        })
        .catch((err) => {
          loginLoading.value = false
          console.log('loginUser', err)
        })
    })
    .catch((err) => console.log('err', err))
}

// 注册

const registerFormRef = ref()
const registerForm = ref({
  account: '',
  password: '',
  repeat_password: '',
  security_code: ''
})
const countDown = ref(60) // 倒计时60s
const isCountDown = ref(false)
const registerLoading = ref(false)

// 获取短信验证码
const getAuthCode = (type = 2) => {
  registerFormRef.value
    .validateField('account')
    .then(async () => {
      try {
        type === 2
          ? await smsCenter({ mobile: registerForm.value.account })
          : await smsCenterFind({ mobile: registerForm.value.account })
        ElMessage.success('验证码已发送，请注意查收')
        isCountDown.value = true
        getCountDown()
      } catch (error) {}
    })
    .catch((err) => console.log('err', err))
}

// 倒计时
const getCountDown = () => {
  let timer = null
  let startTime = 60
  timer = setInterval(() => {
    startTime--
    countDown.value = startTime
    if (startTime <= 0) {
      clearInterval(timer)
      isCountDown.value = false
    }
  }, 1000)
}

const submitRegister = (type = 2) => {
  registerFormRef.value
    .validate()
    .then(async () => {
      try {
        registerLoading.value = true
        const params = {
          ...registerForm.value,
          password: md5(
            'syslogin@' +
              registerForm.value.account.substring(5) +
              '@' +
              registerForm.value.password
          ),
          repeat_password: md5(
            'syslogin@' +
              registerForm.value.account.substring(5) +
              '@' +
              registerForm.value.repeat_password
          )
        }
        type === 2
          ? await registerUser(params)
          : await updatePopSellerPassWord({ ...params, mobile: params.account })
        ElMessage.success('成功，请登录')
        loginView.value = 1
        registerLoading.value = false
        registerFormRef.value.resetFields()
      } catch (error) {
        console.log('error', error)
        registerLoading.value = false
      }
    })
    .catch((err) => console.log('err', err))
}

watch(
  () => loginView.value,
  () => {
    registerFormRef.value?.resetFields()
  }
)
</script>

<style lang="scss" scoped>
.login {
  background: url('../../assets/login_bg.png');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;

  &-footer {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1032px;
    display: flex;

    &__left,
    &__right {
      width: 50%;
      height: 612px;
      overflow: hidden;
    }

    &__left {
      background: url('../../assets/login_left_bg.png');
      background-size: cover;
    }

    &__right {
      background: url('../../assets/login_right_bg.png');
      background-size: cover;
      display: flex;
      flex-direction: column;
      padding: 90px;
      overflow: hidden;
      box-sizing: border-box;
    }
  }

  &-body {
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
  }

  &-title {
    font-size: 28px;
    height: 120px;
  }

  &-form {
    height: 250px;
  }

  .form-item {
    width: 100%;
    border-bottom: 1px solid #cfdbe6;
    height: 44px;
    display: flex;
    align-items: center;

    .icon-default {
      display: block;
    }

    .icon-active {
      display: none;
    }

    &:active,
    &:hover,
    &:focus {
      border-bottom: 2px solid #0071db;

      .icon-default {
        display: none;
      }

      .icon-active {
        display: block;
      }
    }

    &__icon {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &-button {
    width: 100%;
    height: 44px;
  }

  &-action {
    align-self: flex-end;
    width: 100%;

    &__fd {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      height: 40px;
      align-items: center;
    }
  }
}

.register {
  &-title {
    font-size: 28px;
  }
}

.code-btn {
  white-space: nowrap;
}
</style>
<style>
.login {
  .el-input__inner {
    box-shadow: none !important;
    --el-select-input-focus-border-color: none !important;
  }

  .el-input__wrapper {
    background: none !important;
    box-shadow: none !important;
    --el-select-input-focus-border-color: none !important;
  }
}
</style>

<!--
 * @Author: goodLiang
 * @Date: 2024-07-15 13:23:30
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-07-15 16:29:43
 * @FilePath: /business-cms/src/views/weixin/share.vue
-->
<!-- eslint-disable no-undef -->
<template>
  <div>
    <el-button type="primary" size="default" @click="shareWeixin"
      >分享微信</el-button
    >
  </div>
</template>

<script setup>
const JSAPI_TICKET =
  'kgt8ON7yVITDhtdwci0qeSppOECV34HpPYeXYKVT_bfgSrtweCzNzfVRgNuQrMpWhwb4H7k6qcLOq51LbZZpbw'
// eslint-disable-next-line no-undef
ww.register({
  corpId: 'ww3df1914f4d13f8f4',
  jsApiList: ['shareAppMessage'],
  getConfigSignature() {
    // eslint-disable-next-line no-undef
    return ww.getSignature(JSAPI_TICKET)
  }
})

const shareWeixin = () => {
  // eslint-disable-next-line no-undef
  ww.shareAppMessage({
    title: '歌德',
    desc: '歌德老酒行，瓶瓶都保真',
    link: 'https://wxaurl.cn/PjhqvjNs5jh',
    imgUrl:
      'https://res.mail.qq.com/node/ww/wwmng/style/images/index_share_logo$13c64306.png',
    success(result) {
      alert('success' + JSON.stringify(result))
    },
    fail(result) {
      alert('fail' + JSON.stringify(result))
    }
  })
}
</script>

<style lang="scss" scoped></style>

/*
 * @Author: goodLiang
 * @Date: 2023-02-21 15:32:05
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-02-27 13:33:02
 * @FilePath: /zhaoshang/src/api/business.js
 */
import request from '@/utils/request'

/**
 * 提交商户信息
 */
export const submitBusinessInfo = (data = {}) => {
  return request({
    // url: '/admin/seller/openSeller',
    url: '/admin/popSeller/openAccount',
    method: 'POST',
    data
  })
}

/**
 * 获取商户详情
 */
export const getBusinessInfo = (data = {}) => {
  return request({
    // url: '/admin/seller/querySellerInfo',
    url: '/admin/popSeller/queryPopSellerAccountInfo',
    method: 'POST',
    data
  })
}

/**
 * 店铺名称校验
 */
export const getShopValidate = (data = {}) => {
  return request({
    url: '/admin/popSeller/checkPopSellerShopName',
    method: 'POST',
    data
  })
}

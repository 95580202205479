/*
 * @Author: goodLiang
 * @Date: 2023-02-16 19:40:52
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-04 19:53:16
 * @FilePath: /vue3-template/src/views/login/rules.js
 */
let password1 = ''
let password2 = ''
const validatorMobile = () => {
  return (rule, value, callback) => {
    const result = /^1[23456789]\d{9}$/.test(value)
    if (result) {
      callback()
    } else {
      callback(new Error('请输入正确的手机号'))
    }
  }
}
export const loginRules = {
  account: {
    trigger: 'blur',
    validator: validatorMobile()
  },
  password: {
    required: true,
    trigger: 'blur',
    message: '请输入密码'
  }
}
export const registerRules = {
  account: {
    trigger: 'blur',
    validator: validatorMobile()
  },
  password: {
    trigger: 'blur',
    validator: (rule, value, callback) => {
      password1 = value
      if (value.length < 6) {
        callback(new Error('密码长度最小6位，最大10位'))
        return
      }
      if (value !== password2 && password2 !== '') {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
  },
  repeat_password: {
    trigger: 'blur',
    validator: (rule, value, callback) => {
      password2 = value
      if (value.length < 6) {
        callback(new Error('密码长度最小6位，最大10位'))
        return
      }
      if (value !== password1 && password1 !== '') {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
  },
  security_code: {
    required: true,
    trigger: 'blur',
    message: '请输入验证码'
  }
}
